import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { OperatorService } from '../services/operator.service';
import * as OperatorsActions from './operators.actions';
import { concatMap, map, mergeMap, switchMap, throttleTime, withLatestFrom } from 'rxjs';
import * as OperatorSelectors from '../../../core/operators/store/operators.selector';
import { DocumentService } from '../../documents/services/document.service';
import { IDashboardUserSettings, OperatorSettingsDTO } from '../operator-other-settings.model';
import { OperatorDTO } from '../operators.model';
import * as GlobalLanguage from '../../language/language-global';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';
import { produce } from 'immer';
import { delayRequest } from '../../../shared/utility/rxjs-utils';
import * as OperatorsSelectors from "./operators.selector";
import { toSignal } from "@angular/core/rxjs-interop";
import { Translation as PrimeNgTranslation } from "primeng/api/translation";
import { PrimeNG } from "primeng/config";
import { TuiLanguageSwitcherService } from '@taiga-ui/i18n';

@Injectable({ providedIn: 'any' })
export class OperatorsEffect extends NgRxEffectsBase {

  private readonly operatorService = inject(OperatorService);
  private readonly documentService = inject(DocumentService);
  private readonly primengConfig = inject(PrimeNG);
  private readonly tuiLanguageService = inject(TuiLanguageSwitcherService);
  private readonly primengLangObj = toSignal<PrimeNgTranslation>(this.translocoService.selectTranslateObject('primeng'));

  public getAllOperatorsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.retrieveAll),
    switchMap(() => this.operatorService.getAllOperators()
      .pipe(
        map(allOperators => OperatorsActions.retrieveAllSuccess({ allOperators })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.retrieveAllFailure({ httpStatus: null }))
      )
    )
  ));

  public operatorsForOperator$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.operatorsForOperator),
    switchMap(() => this.operatorService.getOperatorsForOperator<OperatorDTO>(false, null, true).pipe(
      map(operators => OperatorsActions.operatorsForOperatorSuccess({ operators })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.operatorsForOperatorFailure({ httpStatus: null }))
    ))
  ));

  public defaultOperatorSettings$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.defaultOperatorSettings),
    withLatestFrom(this.ngrxUtils.selectLoggedOperatorId()),
    switchMap(([, opeId]) => this.operatorService.getDefaultOperatorSettings(opeId).pipe(
      map(settings => OperatorsActions.defaultOperatorSettingsSuccess({ settings })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.defaultOperatorSettingsFailure({ httpStatus: null }))
    ))
  ));

  public updateOperatorSettingsAndDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateOperatorSettingsAndDashboard),
    map(({ dashId, enable, settings }) => {
      let updatedDashboards: IDashboardUserSettings[];
      if (settings.dashboardsList != null) {
        updatedDashboards = settings.dashboardsList.map(d => {
          const dash = { ...d };
          if (dash.dashId === dashId) {
            dash.favorite = enable;
          } else if (dash.favorite) {
            dash.favorite = false;
          }
          return dash;
        });
      } else {
        updatedDashboards = [{
          dashId,
          favorite: enable,
          enabledRefresh: false,
          refreshInterval: 0,
          operators: null,
          excludeDisabledOperators: false,
          filterSettings: [],
          defaultFilterId: null,
          fullScreenMode: false
        }];
      }
      return { ...settings, dashboardsList: updatedDashboards };
    }),
    map(settings => OperatorsActions.updateOperatorSettings({ settings }))
  ));

  public updateOperatorSettings$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateOperatorSettings),
    switchMap(({ settings }) => this.operatorService.updateSettingOperator(settings)
      .pipe(
        map(settings => OperatorsActions.updateOperatorSettingsSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateOperatorSettingsFailure({ httpStatus: null }))
      )
    )
  ));

  public updateDashboardUserFavourite$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateDashboardUserFavourite),
    withLatestFrom(this.ngrxUtils.selectLoggedOperatorId()),
    switchMap(([{ dashId, enable }, opeId]) => this.operatorService.updateDashboardUserFavourite(opeId, dashId, enable)
      .pipe(
        map(settings => OperatorsActions.updateDashboardUserFavouriteSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateDashboardUserFavouriteFailure({ httpStatus: null }))
      )
    )
  ));

  public updateJobPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateJobPreferences),
    withLatestFrom(this.store.select(OperatorSelectors.loggedOperatorSettings)),
    map(([{ jobPreferences }, settings]) => produce(settings, draft => {
      if(draft) {
        if(draft.webUiPreferences) {
          draft.webUiPreferences.jobPreferences = jobPreferences;
        } else {
          draft.webUiPreferences = { jobPreferences };
        }
      }
    })),
    switchMap(settings => this.operatorService.updateSettingOperator(settings as OperatorSettingsDTO)
      .pipe(
        map(settings => OperatorsActions.updateJobPreferencesSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateJobPreferencesFailure({ httpStatus: null }))
      )
    )
  ));

  public updateDevicePreferences$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateDevicePreferences),
    throttleTime(500, undefined, { leading: true, trailing: true }),
    withLatestFrom(this.store.select(OperatorSelectors.loggedOperatorSettings)),
    map(([{ devicePreferences }, settings]) => produce(settings, draft => {
      if(draft) {
        if(draft.webUiPreferences) {
          draft.webUiPreferences.devicePreferences = devicePreferences;
        } else {
          draft.webUiPreferences = { devicePreferences };
        }
      }
    })),
    concatMap(settings => this.operatorService.updateSettingOperator(settings as OperatorSettingsDTO)
      .pipe(
        map(settings => OperatorsActions.updateDevicePreferencesSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateDevicePreferencesFailure({ httpStatus: null }))
      )
    )
  ));

  public updateCalendarPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateCalendarPreferences),
    throttleTime(500, undefined, { leading: true, trailing: true }),
    withLatestFrom(this.store.select(OperatorSelectors.loggedOperatorSettings)),
    map(([{ calendarPreferences, actions }, settings]) => ({
      settings: produce(settings, draft => {
        if(draft) {
          if(draft.webUiPreferences) {
            draft.webUiPreferences.calendarPreferences = calendarPreferences;
          } else {
            draft.webUiPreferences = { calendarPreferences };
          }
        }}),
      actions
    })),
    concatMap(({ settings, actions }) => delayRequest(this.operatorService.updateSettingOperator(settings as OperatorSettingsDTO))
      .pipe(
        mergeMap(settings => [OperatorsActions.updateCalendarPreferencesSuccess({ settings }), ...(actions ?? []) ]),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateCalendarPreferencesFailure({ httpStatus: null }))
      )
    )
  ));

  public updateMonitoringPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateMonitoringPreferences),
    throttleTime(500, undefined, { leading: true, trailing: true }),
    withLatestFrom(this.store.select(OperatorSelectors.loggedOperatorSettings)),
    map(([{ monitoringPreferences }, settings]) => produce(settings, draft => {
      if(draft) {
        if(draft.webUiPreferences) {
          draft.webUiPreferences.monitoringPreferences = monitoringPreferences;
        } else {
          draft.webUiPreferences = { monitoringPreferences };
        }
      }
    })),
    concatMap(settings => this.operatorService.updateSettingOperator(settings as OperatorSettingsDTO)
      .pipe(
        map(settings => OperatorsActions.updateMonitoringPreferencesSuccess({ settings })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateMonitoringPreferencesFailure({ httpStatus: null }))
      )
    )
  ));

  public updateTicketPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateTicketPreferences),
    withLatestFrom(this.store.select(OperatorSelectors.loggedOperatorSettings)),
    map(([{ ticketPreferences, actions }, settings]) => ({
      settings: produce(settings, draft => {
        if(draft) {
          if(draft.webUiPreferences) {
            draft.webUiPreferences.ticketPreferences = ticketPreferences;
          }else{
            draft.webUiPreferences = { ticketPreferences };
          }
        }
      }),
      actions
    })),
    switchMap(({ settings, actions }) => delayRequest(this.operatorService.updateSettingOperator(settings as OperatorSettingsDTO))
      .pipe(
        mergeMap(settings => [ OperatorsActions.updateTicketPreferencesSuccess({ settings }), ...(actions ?? []) ]),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateTicketPreferencesFailure({ httpStatus: null }))
      )
    )
  ));

  public updateUserEmail$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateUserEmail),
    withLatestFrom(this.ngrxUtils.selectLoggedOperatorId()),
    switchMap(([{ email }, opeId]) => this.operatorService.updateUser(opeId, { email })
      .pipe(
        map(() => OperatorsActions.updateUserEmailSuccess({ email })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateUserEmailFailure({ httpStatus: null }))
      )
    )
  ));

  public updateUserPassword$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateUserPassword),
    switchMap(({ password, oldPassword }) => this.operatorService.changeUserPassword(password, oldPassword)
      .pipe(
        map(criptedPwd => OperatorsActions.updateUserPasswordSuccess({ criptedPwd })),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(
          OperatorsActions.updateUserPasswordFailure({
            httpStatus: {
              text: this.translocoService.translate('errorUpdatePassword'),
              type: 'error'
            }
          }),
          'OperatorsEffect - Errore nella modifica della password'
        )
      )
    )
  ));

  public setActiveLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.setActiveLanguage),
    map(({ code }) => {
      this.translocoService.setActiveLang(code);
      switch (code) {
        case 'it':
          this.tuiLanguageService.setLanguage('italian');
          break;
        case 'en':
          this.tuiLanguageService.setLanguage('english');
          break;
        case 'es':
          this.tuiLanguageService.setLanguage('spanish');
          break;
      }
      const primengLangObj = this.primengLangObj();
      if(primengLangObj) {
        this.primengConfig.setTranslation(primengLangObj);
      }
      GlobalLanguage.setLanguage(code);
    })
  ), { dispatch: false });

  public updateProfileImage$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateProfileImage),
    withLatestFrom(this.ngrxUtils.selectLoggedOperatorId()),
    map(([{ file }, opeId]) => {
      const formData = new FormData();
      formData.append('file', file);
      return { file, formData, opeId };
    }),
    switchMap(({ file, formData, opeId }) => this.documentService.uploadDocumentByUserId(formData, 'PUBLIC').pipe(map(img => ({ img, file, opeId })))),
    switchMap(({ img, file, opeId }) => this.operatorService.updateUser(opeId, { photoLink: String(img.id) })
      .pipe(
        map(() => {
          this.documentService.updateDbProfileImg(String(img.id), file, false);
          return OperatorsActions.updateProfileImageSuccess({ img });
        }),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateProfileImageFailure({
          httpStatus: {
            text: this.translocoService.translate('errorUpdateProfileImage'),
            type: 'error'
          }
        }))
      )
    )
  ));

  public updateOperatorSignature$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updateOperatorSignature),
    withLatestFrom(this.store.select(OperatorSelectors.loggedOperator)),
    map(([{ file }, operator]) => {
      const formData = new FormData();
      formData.append('file', file);
      return { file, formData, operator };
    }),
    switchMap(({ file, formData, operator }) => this.documentService.uploadDocumentByUserId(formData, 'PUBLIC')
      .pipe(map(img => ({ img, file, opeId: operator.id })))
    ),
    switchMap(({ img, file, opeId }) => this.operatorService.updateUser(opeId, { signature: String(img.path) })
      .pipe(
        map(() => {
          this.documentService.updateDBOperatorSignature(String(img.path), file, false);
          return OperatorsActions.updateOperatorSignatureSuccess({ img });
        }),
        this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updateOperatorSignatureFailure({
          httpStatus: {
            text: this.translocoService.translate('errorUpdateOperatorSignature'),
            type: 'error'
          }
        }))
      )
    )
  ));

  public updatePlannerPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(OperatorsActions.updatePlannerPreferences),
    switchMap(({ body }) => this.operatorService.updatePlannerPreferences(
      this.store.selectSignal(OperatorsSelectors.loggedOperatorId)() ?? -1,
      body
    ).pipe(
      map(() => OperatorsActions.updatePlannerPreferencesSuccess({ body })),
      this.rxjsUtils.simpleCatchErrorWithLog<Action>(OperatorsActions.updatePlannerPreferencesFailure({ httpStatus: null }))
    ))
  ));
}
