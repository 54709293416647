import { CostCenterDTO } from '../cost-center.model';
import { createReducer, on } from '@ngrx/store';
import * as CostCenterActions from './cost-center.actions';
import * as CostCenterSettingsActions from './../../../features/settings/store/actions/cost-centers.actions';
import * as CostCentersSettingsActions from '../../../features/settings/store/actions/cost-centers.actions';
import { produce } from "immer";

export interface CostCenterState {
  costCenterList: CostCenterDTO[]
  isLoading: boolean
}

export const costCenterInitialState: CostCenterState = {
  costCenterList: [],
  isLoading: false
};

export const costCenterReducer = createReducer(
  costCenterInitialState,

  on(CostCenterActions.getAll, (state) => ({ ...state, isLoading: true })),
  on(CostCenterActions.getAllSuccess, CostCenterSettingsActions.getAllCostCentersSuccess, (state, { costCenters }) => ({ ...state, costCenterList: [...costCenters], isLoading: false })),
  on(CostCenterActions.getAllFailure, (state) => ({ ...state, isLoading: false })),

  on(CostCentersSettingsActions.createCostCenterSuccess, (state, { costCenter }) => produce(state, draft => {
    if(costCenter.default) {
      draft.costCenterList = draft.costCenterList.map(c => {
        if (costCenter.default) {
          c.default = false;
        }
        return c;
      });
    }
    draft.costCenterList.push(costCenter);
  })),

  on(CostCentersSettingsActions.updateCostCenterSuccess, (state, { costCenter } ) => produce(state, draft => {
    draft.costCenterList = draft.costCenterList.map(c => {
      if(costCenter.default) {
        c.default = false;
      }
      if (costCenter.id === c.id) {
        c = costCenter;
      }
      return c;
    });
  })),

  on(CostCenterActions.clearState, () => ({ ...costCenterInitialState }))
);
